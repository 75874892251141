<template>
    <div id="team" class="staff-area pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Our Team</span>
                <h2>Our Staff Profiles</h2>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/staff-5.jpg" alt="image">

                            <ul class="social-link">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>David Driscoll</h3>
                            <span>Manager</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/staff-6.jpg" alt="image">

                            <ul class="social-link">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Andon Wardin</h3>
                            <span>Loan Officer</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/staff-7.jpg" alt="image">

                            <ul class="social-link">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Mark Morrell</h3>
                            <span>Area Managerr</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/staff-8.jpg" alt="image">

                            <ul class="social-link">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Jubin Indona</h3>
                            <span>Accountant</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurStaff'
}
</script>