<template>
    <div>
        <TopHeaderOne />
        <NavbarOne />
        <MainBanner />
        <AboutOurCompany />
        <Counter />
        <OurCoreService subTitle="WHAT WE OFFER" heading="Our Core Service" />
        <BuyingHome />
        <WhyChooseUs />
        <Quote />
        <AmongThem subTitle="WHY CHOOSE US" heading="Among Them We’re" />
        <OurTeam />
        <Faq />
        <Testimonials />
        <Contact />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import TopHeaderOne from '../Layouts/TopHeaderOne'
import NavbarOne from '../Layouts/NavbarOne'
import MainBanner from '../HomeOne/MainBanner'
import AboutOurCompany from '../HomeOne/AboutOurCompany'
import Counter from '../Common/Counter'
import OurCoreService from '../Common/OurCoreService'
import BuyingHome from '../HomeOne/BuyingHome'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import Quote from '../HomeOne/Quote'
import AmongThem from '../Common/AmongThem'
import OurTeam from '../Common/OurTeam'
import Faq from '../HomeOne/Faq'
import Testimonials from '../HomeOne/Testimonials'
import Contact from '../HomeOne/Contact'
import Blog from '../Common/Blog'
import Subscribe from '../HomeOne/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageOne',
    components: {
        TopHeaderOne,
        NavbarOne,
        MainBanner,
        AboutOurCompany,
        Counter,
        OurCoreService,
        BuyingHome,
        WhyChooseUs,
        Quote,
        AmongThem,
        OurTeam,
        Faq,
        Testimonials,
        Contact,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>