<template>
    <div>
        <div class="video-overview-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="video-overview-image">
                            <img src="../../assets/img/more-home/video-overview.jpg" alt="image">

                            <a href="javascript:void(0)" class="video-btn" v-on:click="isPopupMethod(isPopup)">
                                <i class='bx bx-play'></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="video-overview-content">
                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <p style="text-align: justify;">En cualquier momento de tu vida, podría presentarse una
                                    situación con tu auto que te
                                    ponga en apuros económicos. Ante este tipo de escenarios desfavorables, es en donde
                                    entra el papel de nuestro seguro auto, el cual te brindará el respaldo necesario para
                                    poder afrontar los problemas que se te puedan presentar.
                                </p>
                            </div>

                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <p style="text-align: justify;">Nuestro Seguro de auto en México te dará esa sensación de
                                    tranquilidad, pues te responderá en caso de que seas víctima, o sufras un accidente. Te
                                    ayudará a que cuides de tu patrimonio, y el de tu familia.

                                </p>
                            </div>

                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <p style="text-align: justify;">Podrás Cotizar seguro de auto a excelentes precios, además
                                    de poder elegir la cobertura que mejor se adapte a lo que realmente estás buscando
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-video" v-if="isPopup">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/QSG8Psuawbk"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoOverview',
    data() {
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup) {
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>