<template>
    <div>
        <NavbarThree />
        <MainBanner />
        <GetIntroduced />
        <VideoOverview />
        <Services class="with-black-bg" />
        <MortgageQuote />
        <Contact />
        <!--<ConsumerFeedbacks />-->
        <SubscribeTwo />
        <FooterThree />
    </div>
</template>

<script>
import NavbarThree from '../Layouts/NavbarThree'
import MainBanner from '../HomeEight/MainBanner'
import GetIntroduced from '../HomeEight/GetIntroduced'
import VideoOverview from '../HomeEight/VideoOverview'
import Services from '../Common/Services'
import MortgageQuote from '../Common/MortgageQuote'
import Contact from '../HomeSeven/Contact'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageEight',
    components: {
        NavbarThree,
        MainBanner,
        GetIntroduced,
        VideoOverview,
        Services,
        MortgageQuote,
        Contact,
        SubscribeTwo,
        FooterThree,
    }
}
</script>