<template>
    <div id="contact" class="one-contact-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-5 p-0">
                    <div class="contact-img">
                        <img src="../../assets/img/home-one/contact.jpg" alt="Conatact">
                    </div>
                </div>
                <div class="col-lg-7 p-0">
                    <div class="contact-content">
                        <div class="one-section-title">
                            <span class="sub-title">LET’S CONTACT</span>
                            <h2>Contact With Us</h2>
                        </div>
                        <form id="contactForm">
                            <div class="row m-0">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-user'></i>
                                        </label>
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-mail-send'></i>
                                        </label>
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-phone-call'></i>
                                        </label>
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
        
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bxs-edit-alt'></i>
                                        </label>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-message-square-detail'></i>
                                        </label>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Message"></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="row m-0">
                                        <div class="col-sm-4 col-lg-4 pl-0">
                                            <button type="submit" class="contact-btn btn">
                                                <i class='bx bx-plus'></i>
                                                Send Message
                                            </button>
                                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="col-sm-8 col-lg-8">
                                            <div class="contact-emergency">
                                                <i class='bx bx-phone-call'></i>
                                                <span>Emergency Contact</span>
                                                <a href="tel:+44554348554">+445 543 48554</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>