<template>
    <div class="mortgage-quote-area pb-100">
        <div class="container">
            <div class="row m-0">
                <div class="col-lg-6 col-md-6 p-0">
                    <div class="mortgage-quote-content">
                        <h3>Contamos con diferentes formas de pago:</h3>
                        <ul class="list">
                            <li style="color:#ffffff">Contado</li>
                            <li style="color:#ffffff">Semestral</li>
                            <li style="color:#ffffff">Trimestral</li>
                            <li style="color:#ffffff">Mensual</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 p-0">
                    <div class="mortgage-quote-image"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MortgageQuote'
}
</script>