import { createWebHistory, createRouter } from "vue-router";

import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import HomePageFour from "../components/Pages/HomePageFour";
import HomePageFive from "../components/Pages/HomePageFive";
import HomePageSix from "../components/Pages/HomePageSix";
import HomePageSeven from "../components/Pages/HomePageSeven";
import HomePageEight from "../components/Pages/HomePageEight";
import HomePageNine from "../components/Pages/HomePageNine";
import HomePageTen from "../components/Pages/HomePageTen";
import HomePageEleven from "../components/Pages/HomePageEleven";
import HomePageTwelve from "../components/Pages/HomePageTwelve";

const routes = [
  { path: "/", name: "HomePageEight", component: HomePageEight },
  { path: "/home-two", name: "HomePageTwo", component: HomePageTwo },
  { path: "/home-three", name: "HomePageThree", component: HomePageThree },
  { path: "/home-four", name: "HomePageFour", component: HomePageFour },
  { path: "/home-five", name: "HomePageFive", component: HomePageFive },
  { path: "/home-six", name: "HomePageSix", component: HomePageSix },
  { path: "/home-seven", name: "HomePageSeven", component: HomePageSeven },
  { path: "/home-nine", name: "HomePageNine", component: HomePageNine },
  { path: "/home-page-one", name: "HomePageOne", component: HomePageOne },
  { path: "/home-ten", name: "HomePageTen", component: HomePageTen },
  { path: "/home-eleven", name: "HomePageEleven", component: HomePageEleven },
  { path: "/home-twelve", name: "HomePageTwelve", component: HomePageTwelve },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
