<template>
    <div class="two-lover-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <h2>¿Que dice la gente de nosotros?</h2>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul class="align-items-center">
                            <li>
                                <div class="lover-img">
                                    <img src="../../assets/img/home-two/lover1.png" alt="Lover">
                                    <i class='bx bxs-quote-alt-left'></i>
                                </div>
                            </li>
                            <li>
                                <div class="lover-content">
                                    <h3>Cristiano Ronaldo</h3>
                                    <p>Excelente trato y resolvieron mis dudas muy agradecido.</p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul class="align-items-center">
                            <li>
                                <div class="lover-img">
                                    <img src="../../assets/img/home-two/lover2.png" alt="Lover">
                                    <i class='bx bxs-quote-alt-left'></i>
                                </div>
                            </li>
                            <li>
                                <div class="lover-content">
                                    <h3>Kylian Mbappe</h3>
                                    <p>Realemente recomendaria contrar un seguro, muy buen
                                        servicio</p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoustomerFeedbacks'
}
</script>