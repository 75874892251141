<template>
    <div class="subscribe-style-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="subscribe-style-wrap-content">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <h2>¿Quieres obtener una actualización de lo que está por venir?</h2>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <form class="newsletter-form"
                                action="https://ibsconsultores.us9.list-manage.com/subscribe/post?u=d0cf3c0d58b4a13b5803ae33e&amp;id=6f7f675184&amp;f_id=00d411e1f0"
                                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
                                <input type="email" class="form-control" placeholder="Ingresa tu email" name="EMAIL"
                                    required>

                                <button class="main-default-btn" type="submit">
                                    Subscribete ahora
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscribeTwo'
}
</script>