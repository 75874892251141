<template>
    <div class="one-testimonial-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">TESTIMONIALS</span>
                <h2>People Are Saying</h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="testimonial-item">
                        <div class="testimonial-top">
                            <div class="test-img">
                                <img src="../../assets/img/home-one/testimonial1.png" alt="Testimonial">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                            <ul>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                            </ul>
                            <h3>ERIC HUANG</h3>
                            <span>Homeowners</span>
                        </div>
                        <div class="testimonial-bottom">
                            <span>Friend For Life!</span>
                            <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="testimonial-item active">
                        <div class="testimonial-top">
                            <div class="test-img">
                                <img src="../../assets/img/home-one/testimonial2.png" alt="Testimonial">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                            <ul>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star'></i>
                                </li>
                            </ul>
                            <h3>DONNEY JON</h3>
                            <span>Enton gov inc.</span>
                        </div>
                        <div class="testimonial-bottom">
                            <span>Awesome Agency</span>
                            <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="testimonial-item">
                        <div class="testimonial-top">
                            <div class="test-img">
                                <img src="../../assets/img/home-one/testimonial3.png" alt="Testimonial">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                            <ul>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                                <li>
                                    <i class='bx bxs-star checked'></i>
                                </li>
                            </ul>
                            <h3>EDWARD H. BURNBAUM</h3>
                            <span>A. Schwartz & Associates</span>
                        </div>
                        <div class="testimonial-bottom">
                            <span>Recommending Mortgage</span>
                            <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonials'
}
</script>