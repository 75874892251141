<template>
    <div id="services" class="core-service-area ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Nuestros servicios</span>
                <h2>Te ayudamos en todo momento</h2>
            </div>

            <div class="core-service-list-tab">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="core-service-1-tab" data-bs-toggle="tab" href="#core-service-1"
                            role="tab" aria-controls="core-service-1">Responsabilidad Civil</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-2-tab" data-bs-toggle="tab" href="#core-service-2" role="tab"
                            aria-controls="core-service-2">Limitada</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-3-tab" data-bs-toggle="tab" href="#core-service-3" role="tab"
                            aria-controls="core-service-3">Amplia</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-4-tab" data-bs-toggle="tab" href="#core-service-4" role="tab"
                            aria-controls="core-service-4">Amplia plus</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-5-tab" data-bs-toggle="tab" href="#core-service-5" role="tab"
                            aria-controls="core-service-5">Renovación</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-book-reader"></i>
                                    </div>
                                    <h3>Responsabilidad Civil</h3>
                                    <p style="text-align: justify;">En muchos lugares, tener una póliza de responsabilidad
                                        civil de autos es un requisito
                                        legal para conducir, ya que asegura que las personas involucradas en un accidente
                                        puedan recibir la compensación necesaria para cubrir los costos y daños causados.
                                    </p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura de daños a terceros</li>
                                        <li><i class='bx bx-chevrons-right'></i> Límites de cobertura</li>
                                        <li><i class='bx bx-chevrons-right'></i> Exclusiones comunes</li>
                                        <li><i class='bx bx-chevrons-right'></i> Requisitos legales y financieros</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-1.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-user-voice"></i>
                                    </div>
                                    <h3>Limitada</h3>
                                    <p style="text-align: justify;">
                                        Una póliza limitada de autos es un tipo de seguro de vehículos que ofrece una
                                        cobertura más específica y restringida en comparación con una póliza de seguro de
                                        autos estándar. </p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i>Cobertura específica </li>
                                        <li><i class='bx bx-chevrons-right'></i> Límites de cobertura más bajos</li>
                                        <li><i class='bx bx-chevrons-right'></i> Exclusiones y restricciones</li>
                                        <li><i class='bx bx-chevrons-right'></i> Primas más bajas</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-2.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-user"></i>
                                    </div>
                                    <h3>Amplia</h3>
                                    <p style="text-align: justify;">Esta póliza brinda mayor tranquilidad al asegurado, ya
                                        que protege contra una amplia
                                        variedad de escenarios potenciales y ayuda a mitigar los riesgos financieros
                                        asociados con los accidentes automovilísticos y otros eventos imprevistos.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura de responsabilidad civil</li>
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura contra daños propios</li>
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura extendida</li>
                                        <li><i class='bx bx-chevrons-right'></i> Límites de cobertura más altos</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-3.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-4" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-user-plus"></i>
                                    </div>
                                    <h3>Amplia plus</h3>
                                    <p style="text-align: justify;">Una póliza amplia plus de autos es una forma avanzada y
                                        completa de seguro vehicular que ofrece una extensa cobertura tanto para daños a
                                        terceros como para los propios daños del vehículo asegurado. Esta categoría de
                                        seguro está diseñada para brindar una protección aún más amplia que la póliza amplia
                                        estándar, incluyendo beneficios y servicios adicionales. </p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura de responsabilidad civil</li>
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura contra daños propios</li>
                                        <li><i class='bx bx-chevrons-right'></i> Cobertura extendida</li>
                                        <li><i class='bx bx-chevrons-right'></i> Beneficios adicionales</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-4.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-5" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-happy-heart-eyes"></i>
                                    </div>
                                    <h3>Renovación</h3>
                                    <p style="text-align: justify;">Esta renovación permite que el asegurado continúe
                                        disfrutando de la protección y los beneficios proporcionados por su póliza de seguro
                                        anterior. </p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Continuación de la cobertura</li>
                                        <li><i class='bx bx-chevrons-right'></i> Reevaluación de tarifas</li>
                                        <li><i class='bx bx-chevrons-right'></i> Beneficios y cobertura</li>
                                        <li><i class='bx bx-chevrons-right'></i> Opciones de pago</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-5.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>