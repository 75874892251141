<template>
    <div id="home" class="banner-buy-area">
        <div class="container-fluid">
            <div class="banner-buy-content">
                <span class="sub-title">Segurauto | El mejor seguro para tu auto.</span>
                <h1>Aseguramos tu auto rapido.</h1>
                <p>Todas las coberturas en la palma de tu mano</p>

                <ul class="banner-buy-btn">
                    <li>
                        <a href="https://ibs_w.segupoliza.com/#/quotes/new" Target="_blank"
                            class="main-default-btn">Asegurate ahora</a>
                    </li>

                </ul>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-time'></i>
                        </div>
                        <p>
                            <span>Lun - Vie (8:30am - 6:00pm)</span>
                            <span>Sabados (9:00am - 1:00pm)</span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bxs-contact'></i>
                        </div>
                        <p>
                            <span>Contacto</span>
                            <span><a href="mailto:contacto@ibsconsultores.com">contacto@ibsconsultores.com</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <p>
                            <span>Número Télefono</span>
                            <span><a href="tel:4234543123678">+52 800 777 09 11</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-world'></i>
                        </div>
                        <p>
                            <span>Manuel González Cossío #3703, Las Granjas, Chihuahua, Chih. CP 31100 </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>