<template>
    <div>
        <div id="home" class="one-banner-area">
            <div class="banner-shape">
                <img src="../../assets/img/home-one/banner-shape1.png" alt="Banner">
                <img src="../../assets/img/home-one/banner-shape2.png" alt="Banner">
            </div>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <div class="banner-video">
                                <a 
                                    href="javascript:void(0)" 
                                    class="popup-youtube"
                                    v-on:click="isPopupMethod(isPopup)"
                                >
                                    <i class='bx bx-play' ></i>
                                </a>
                                <span>Into With Compnay</span>
                            </div>
                            <h1>Mortgage Is A Great For You</h1>
                            <p>Reliable Sustainable Provider</p>

                            <div class="banner-form">
                                <div class="banner-form-span">
                                    <span>Calculators</span>
                                </div>
                                <form @submit.prevent="calculate">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Purchase price *"
                                                    v-model.number="purchasePrice"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <span class="form-control">
                                                    Monthly: {{ monthlyPayment.toFixed(2) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input 
                                                    type="text" 
                                                    class="form-control"
                                                    placeholder="Down payment *"
                                                    v-model.number="downPayment" 
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Property ZIP code *"
                                                    v-model.number="zipCode"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <select v-model.number="numMonths" class="form-select">
                                                    <option value="10">10 months</option>
                                                    <option value="12" selected>12 months</option>
                                                    <option value="24">24 months</option>
                                                    <option value="36">36 months</option>
                                                    <option value="48">48 months</option>
                                                    <option value="60">60 months</option>
                                                    <option value="72">72 months</option>
                                                    <option value="84">84 months</option>
                                                </select>	
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input 
                                                    type="email" 
                                                    class="form-control" 
                                                    placeholder="Email address"
                                                    v-model.number="email"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <button type="submit" class="btn banner-form-btn">
                                                Calculate money
                                            </button>
                                        </div>
                                        <div class="col-lg-6">
                                            <a class="banner-call" href="tel:5432123451">
                                                Call Us: 5432 123 451 (Sat - Thu)
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="banner-slider">
                            <carousel 
                                :autoplay="4000"
                                :wrap-around="true"
                            >
                                <slide v-for="slide in carouselItems" :key="slide.id">
                                    <div :class="slide.class">
                                        <img src="../../assets/img/home-one/banner-main-shape.png" alt="Shape">
                                    </div>
                                </slide>

                                <template #addons>
                                    <Navigation />
                                </template>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation , Slide, } from 'vue3-carousel';

export default {
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            carouselItems: [
                {
                    id: 1,
                    class: 'banner-inner banner-img-one',
                },
                {
                    id: 2,
                    class: 'banner-inner banner-img-two',
                },
                {
                    id: 3,
                    class: 'banner-inner banner-img-three',
                },
            ],
            isPopup: false,
            purchasePrice: '',
            downPayment: '',
            zipCode: '',
            numMonths: 12,
            email: '',
            monthlyPayment: 0,
        }
    },
    computed: {
        formValid() {
            const { purchasePrice, downPayment, numMonths } = this;
            return (
                +purchasePrice -downPayment >= 0 &&
                +numMonths > 0
            );
        },
    },
    methods: {
        calculate() {
            if (!this.formValid) {
                return;
            }
            const { purchasePrice, downPayment, numMonths } = this;
            this.monthlyPayment = (purchasePrice -downPayment) / numMonths;
        },
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>