<template>
    <div id="contact" class="let-contact-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-form">
                        <h3>¡Escríbenos! Nosotros te asesoramos</h3>

                        <form id="contact-form" ref="form" @submit.prevent="sendEmail">
                            <div class="row m-0">
                                <div class="col-sm-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Nombre</label>
                                        <input type="text" name="con_name" class="form-control" required>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="con_email" class="form-control" required>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Télefono</label>
                                        <input type="text" name="con_telefono" required class="form-control" maxlength="10">
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Mensaje</label>
                                        <textarea name="con_message" class="form-control" cols="30" rows="8"
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12">
                                    <div class="let-contact-btn">
                                        <button type="submit" class="main-default-btn">Enviar mensaje</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28008.477617221233!2d-106.10237999999998!3d28.657930999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ea431a4faceded%3A0x34654aee4cb10d82!2sIBS%20Consultores%20Asesores%20en%20Seguros%20%26%20Fianzas!5e0!3m2!1ses-419!2smx!4v1691164792822!5m2!1ses-419!2smx"
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import Swal from "sweetalert2";

export default {
    methods: {
        sendEmail() {
            emailjs.sendForm('service_o10ni4h', 'template_oxm9dz6', this.$refs.form, '9dXIW13fiQT0eCgk5')
                .then(() => {
                    location.href = "/contact/";
                })
                .catch((error) => {
                    alert(error);
                });
            Swal.fire({
                icon: "success",
                title: "En un momento un asesor se comunicara contigo.",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
}
</script>