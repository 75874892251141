<template>
    <div id="about" class="one-about-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../assets/img/home-one/about.png" alt="About">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <div class="one-section-title">
                            <span class="sub-title">ABOUT OUR COMPANY</span>
                            <h2>Why Should You Choose Us?</h2>
                            <p>Campaign dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam, </p>
                        </div>
                        <div class="about-tablist">
                            <div class="tabs">
                                <ul class="tabs__header">
                                    <li 
                                        class="tabs__header-item" 
                                        v-for="tab in tabs" 
                                        v-on:click="selectTab (tab.id)" 
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        <i :class="tab.icon"></i>
                                        {{tab.name}}
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs__container">
                                <ul 
                                    class="tabs__list"
                                    ref='tabsList'
                                >
                                    <li 
                                        class="tabs__list-tab"
                                        v-for="tab in tabs"
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                       <p>{{tab.content}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="about-best">
                            <ul>
                                <li>
                                    <h4>Best Mortgage Since 2002.</h4>
                                </li>
                                <li>
                                    <img src="../../assets/img/home-one/about=sig.png" alt="About">
                                    <div class="sig-inner">
                                        <span>Donakd Zain</span>
                                        <p>CEO At Cavo</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-bullseye',
                    name: 'Our Mission',
                    content: 'Compnay dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam'
                },
                {
                    id: 2,
                    icon: 'bx bx-show-alt',
                    name: 'OUR VISION',
                    content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less'
                },
                {
                    id: 3,
                    icon: 'bx bx-pyramid',
                    name: 'OUR GOAL',
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look'
                }
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>