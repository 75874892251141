<template>
    <footer class="footer-style-area pt-100">
        <div class="container">
            <div class="copyright-style-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="copyright-item">
                            <p>Copyright @2023. Designed By <a href="https://ibsconsultores.com/" target="_blank">IBS
                                    Consultores S.C.</a></p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="copyright-item">
                            <ul class="payment-list">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/SegurautoOficial">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/segurautooficial/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterThree'
}
</script>