<template>
    <div id="service" class="one-service-area ptb-100">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">{{subTitle}}</span>
                <h2>{{heading}}</h2>
                <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua</p>
            </div>
            <nav class="tabs">
                <div class="tabs__header">
                    <a 
                        class="tabs__header-item" 
                        v-for="tab in tabs" 
                        v-on:click="selectTab (tab.id)" 
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        <div class="service-item">
                            <i :class="tab.icon"></i>
                            <span>{{tab.name}}</span>
                        </div>
                    </a>
                </div>
            </nav>
            <div class="tabs__container">
                <div 
                    class="tabs__list"
                    ref='tabsList'
                >
                    <div 
                        class="tabs__list-tab"
                        v-for="tab in tabs"
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img :src="tab.image" alt="Advisor">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>{{tab.title}}</h2>
                                        <p>{{tab.shortDesc}}</p>
                                        <ul>
                                            <li
                                                v-for="list in tab.lists"
                                                :key="list.id"
                                            >
                                                <i :class='list.icon'></i>
                                                {{list.text}}
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurCoreService',
    props: ['subTitle', 'heading'],
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bxs-building-house',
                    name: 'Purchase New House',
                    image: require('../../assets/img/home-one/advisor.jpg'),
                    title: 'Purchase New House',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                },
                {
                    id: 2,
                    icon: 'bx bxs-dollar-circle',
                    name: 'Apply For Loan',
                    image: require('../../assets/img/home-one/advisor2.jpg'),
                    title: 'Apply For Loan',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                },
                {
                    id: 3,
                    icon: 'bx bxs-home-circle',
                    name: 'Refinancing Your Home',
                    image: require('../../assets/img/home-one/advisor3.jpg'),
                    title: 'Refinancing Your Home',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                },
                {
                    id: 4,
                    icon: 'bx bxs-hand-up',
                    name: 'Capital Management',
                    image: require('../../assets/img/home-one/advisor4.jpg'),
                    title: 'Capital Management',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                },
                {
                    id: 5,
                    icon: 'bx bxs-wrench',
                    name: 'Renovation House',
                    image: require('../../assets/img/home-one/advisor5.jpg'),
                    title: 'Renovation House',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                },
                {
                    id: 6,
                    icon: 'bx bxs-user-circle',
                    name: 'Refinance Advisor',
                    image: require('../../assets/img/home-one/advisor6.jpg'),
                    title: 'Refinance Advisor',
                    shortDesc: 'Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Respect for all people',
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Excellence in everything we do',
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Truthfulness in our business',
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: 'Unquestionable integrity',
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>