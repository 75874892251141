<template>
    <nav :class="['main-navbar navbar navbar-expand-md navbar-light', { 'is-sticky': isSticky }]">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <img class="black-logo" src="../../assets/img/logo.png" alt="Logo">
                <img class="white-logo" src="../../assets/img/logo-two.png" alt="Logo">
            </router-link>

            <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                v-bind:class="{ 'active': button_active_state }" v-on:click="button_active_state = !button_active_state">
                <i class='bx bx-menu'></i>
                <i class='bx bx-x'></i>
            </div>

            <div class="collapse navbar-collapse" :class="{ toggler: active }">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" @click="scroll('home')" href="javascript:void(0)">Inicio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="scroll('about')" href="javascript:void(0)">Nosotros</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="scroll('services')" href="javascript:void(0)">Servicios</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="scroll('contact')" href="javascript:void(0)">Contacto</a>
                    </li>
                    <li class="nav-item">
                        <a class="cmn-banner-btn" href="https://ibs_w.segupoliza.com/#/quotes/new" target="_blank"><i
                                class='bx bx-car'></i>
                            Cotiza
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarThree',
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    methods: {
        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
}
</script>