<template>
    <div class="one-help-area ptb-100">
        <div class="help-img">
            <img src="../../assets/img/home-one/help.png" alt="Help">
        </div>
        <div class="container">
            <div class="help-item">
                <div class="one-section-title">
                    <span class="sub-title">BUYING A HOME</span>
                    <h2>We Are Here To Help You</h2>
                </div>
                <ul>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-brain'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Make it Your Dream</h3>
                            <p>When you own your own homet seeturlit sed dolor sit am</p>
                        </div>
                    </li>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-home-alt'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Create A Place</h3>
                            <p>You can grow in your new homeit amet seeturlit sed dolor sit </p>
                        </div>
                    </li>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-brain'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Be A Part Of Community</h3>
                            <p>Buying a home is an investmemet seeturlit sed dolo ameol</p>
                        </div>
                    </li>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-home-alt'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Pay Principal</h3>
                            <p>Gain home equity by paying sit amet seeturlit sed dolor sit </p>
                        </div>
                    </li>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-brain'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Get Tax Benefits</h3>
                            <p>Take advantage of tax benefitsit amet seeturlit sed dolor sit </p>
                        </div>
                    </li>
                    <li>
                        <div class="help-icon">
                            <i class='bx bx-home-alt'></i>
                        </div>
                        <div class="help-inner">
                            <h3>Build Credit</h3>
                            <p>Our plan dolor sit amet seeturlit sed dolor sit ameolor sit </p>
                        </div>
                    </li>
                </ul>
                <div class="advisor-link">
                    <a href="#contact">
                        GET STARTED
                    </a>
                    <i class="bx bx-right-arrow-alt"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyingHome'
}
</script>